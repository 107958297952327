import { Form, Spinner } from "react-bootstrap";
import { useGetPromptContent, usePostPromptContent } from "../services/Query";
import { useEffect, useState } from "react";

export const PreloadAIAdminPrompt = () => {
  const { data: prompt, isLoading, refetch } = useGetPromptContent();
  const [content, setContent] = useState("");
  const { mutate: savePrompt, isLoading: isSaving } = usePostPromptContent();
  const onUpdatePrompt = () => {
    const data = prompt
      ? { id: prompt.id, preLoadDescription: content, description : prompt.description }
      : { preLoadDescription: content };
    savePrompt(JSON.stringify(data), {
      onSuccess: () => {
        refetch();
      },
    });
  };
  useEffect(() => {
    if (prompt) {
      setContent(prompt.preLoadDescription);
    }
  }, [prompt]);
  const expectedComment = `
  Example expected json type:
  {
    name: "",
    description: "",
    key_concepts: ["", "", ""],
    preparation: [
      "”,
      “”,
      “”,
      “"
    ],
    instructions: [
      "",
      "",
      "",
      "",
      ""
    ],
    additional_notes: "",
    materials: ["", "", ""],
    prep_time: "",
    class_time: "",
    work_types: ["", ""],
    reusable: "",
    template_categories: ["", "", "", ""]
  }
  `;
  return (
    <div className="container searchform">
      <h3> AI prompt structure</h3>
      <div className="mt-3 mb-3">
        <h5>course information</h5>
        <span>[ course name ]</span> <br />
        <span>[ subject/domain ]</span> <br />
        <span>[ companion print title(s) ]</span> <br />
        <span>[ target grade level(s) ]</span> <br />
        <span>[ course description ]</span> <br />
        <h5 className="mt-2">unit information</h5>
        <span>[ unit name ]</span> <br />
        <span>[ key ideas ]</span> <br />
        <span>[ companion printed title(s) ]</span> <br />
        <span>[ pages or page range ]</span> <br />
        <span>[ unit description ]</span> <br />
        <h5 className="mt-2">lesson information</h5>
        <span>[ lesson title ]</span> <br />
        <span>[ lesson objective(s) ]</span> <br />
        <span>[ lesson vocabulary ]</span> <br />
      </div>
      <Form.Group className="mt-3">
        <h5>admin editable section for preload contents:</h5>
        <Form.Control
          as={"textarea"}
          rows={20}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          value={content}
          placeholder="prompt content"
        />
      </Form.Group>
      <div className="d-flex justify-content-end my-2">
        <button
          className="prim_btn px-3 py-1 rounded-2 fw-bold border-0 shadow-sm "
          disabled={content == ""}
          onClick={() => {
            onUpdatePrompt();
          }}
        >
          {isSaving ? (
            <Spinner
              animation="border"
              size="sm"
              className="me-1"
              variant="info"
            />
          ) : null}
          save prompt
        </button>
      </div>
      <div className="mt-3 mb-3">
        <h5>description of a new activity idea (input by users)</h5>
        <span>[ ... ]</span> <br />
        <h5 className="mt-2">json format (output format)</h5>
        <span>{`{`}</span> <br />
        <span className="ms-4">{`name: "",`}</span> <br />
        <span className="ms-4">{`description: "",`}</span> <br />
        <span className="ms-4">{`key_concepts: ["", "", ""],`}</span> <br />
        <span className="ms-4">{`preparation: [`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`""`}</span> <br />
        <span className="ms-4">{`],`}</span> <br />
        <span className="ms-4">{`instructions: [`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`"",`}</span> <br />
        <span className="ms-5">{`""`}</span> <br />
        <span className="ms-4">{`],`}</span> <br />
        <span className="ms-4">{`additional_notes: "",`}</span> <br />
        <span className="ms-4">{`materials: ["", "", ""],`}</span> <br />
        <span className="ms-4">{`prep_time: "",`}</span> <br />
        <span className="ms-4">{`prep_time: "",`}</span> <br />
        <span className="ms-4">{`class_time: "",`}</span> <br />
        <span className="ms-4">{`work_types: ["", ""],`}</span> <br />
        <span className="ms-4">{`reusable: "",`}</span> <br />
        <span className="ms-4">{`template_categories: ["", "", "", ""]`}</span>{" "}
        <br />
        <span>{`}`}</span> <br />
      </div>
    </div>
  );
};
