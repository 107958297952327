import moment from 'moment-timezone';

import React, { useRef, useState } from 'react';
import { Card, Form, InputGroup, Spinner } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ConfirmModal } from '../components/ConfirmModal';
import HttpService from '../services/HttpServices';
import { UseDeleteUser, useGetUsers, useSaveUser } from '../services/Query';
import TokenServices from '../services/TokenServices';
import { UserInfo } from '../stores';
import { useForm } from 'react-hook-form';
import { Member, levelName } from '../models';
import { AddUserModal } from '../components/modal/AddUserModal';
type FrmUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  schoolName: string;
  typeOfSchool: string;
  country: string;
  province: string;
  city: string;
};
export const Users = () => {
  const {
    isLoading: userLoading,
    data: users,
    isFetching: userFetching,
    error: errorLoading,
    refetch: getUsers,
  } = useGetUsers();
  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<FrmUser>({ mode: 'onChange' });

  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const navigate = useNavigate();
  const msgRole1 =
    'Do you want to change the user role to "Verified Contributors"?';
  const msgRole2 = 'Do you want to change the user role to "Regular Teacher"?';
  const msgUser1 = 'Do you want to stop access from this user?';
  const msgUser2 = 'Do you want this user to allow access?';
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const memberId = useRef<string>();
  const checkType = useRef<number>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const { mutate: deleteUser, isLoading: isDeleting } = UseDeleteUser();

  const [isEdit, setIsEdit] = useState(false);
  const renderTime = (vTime: string) => {
    const pos = vTime.indexOf('.');
    const rTime = vTime.substring(0, pos) + '-00:00';
    return moment.tz(rTime, 'America/New_York').format('YYYY-MM-DD HH:mm:ss');
  };
  const isContributor = (val: string) => {
    return val === 'contributor';
  };
  const renderType = (val: string) => {
    return val === 'contributor' ? 'verified' : 'regular';
  };
  const renderValid = (isValid: boolean) => {
    return isValid ? 'valid' : 'invalid';
  };
  const { mutate: saveUser, isLoading: isSaving } = useSaveUser();
  const [addUsers, setAddUsers] = useState(false);
  if (userLoading || userFetching) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={'80px'}
          width={'80px'}
          color="#666666"
        />
      </div>
    );
  }
  if (errorLoading && errorLoading.message.indexOf('401') > -1) {
    // console.log(errorLoading?.message);
    TokenServices.updateLocalAccessToken('');
    setUserinfo(undefined);
    navigate('/login');
  }
  const onRegUser = () => {
    const data = getValues();
    saveUser(JSON.stringify(data), {
      onSuccess: () => {
        getUsers();
        setIsEdit(false);
      },
      onError: () => {
        alert('error');
      },
    });
  };
  const onEditItem = (item: Member) => {
    setIsEdit(true);
    reset({
      id: item.member_Id,
      firstName: item.member_FirstName,
      lastName: item.member_LastName,
      email: item.member_Email,
      confirmEmail: item.member_ConfirmEmail,
      schoolName: item.member_SchoolName,
      typeOfSchool: item.member_SchoolType,
      country: item.member_Country,
      province: item.member_Province,
      city: item.member_City,
    });
  };
  const { id } = watch();
  if (isEdit) {
    return (
      <div>
        <Card className="w-50">
          <Card.Header>Add User</Card.Header>
          <Card.Body>
            <div className="d-grid gap-3">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('firstName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('lastName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('email', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Confirm Email</InputGroup.Text>
                <Form.Control type="text" {...register('confirmEmail')} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>School Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('schoolName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>type of school</InputGroup.Text>
                <Form.Control type="text" {...register('typeOfSchool')} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>country</InputGroup.Text>
                <Form.Control type="text" {...register('country')} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>state/province</InputGroup.Text>
                <Form.Control type="text" {...register('province')} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>city</InputGroup.Text>
                <Form.Control type="text" {...register('city')} />
              </InputGroup>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            {id && (
              <button
                className="bg-white rounded-2 px-3 me-2 text-danger"
                onClick={() => {
                  setDeleteConfirm(true);
                  setDeleteMsg(
                    `Are you sure you want to remove the user from Edusfere?`
                  );
                }}
              >
                delete
              </button>
            )}
            <button
              className="bg-white rounded-2 px-3"
              disabled={!isValid}
              onClick={onRegUser}
            >
              {isSaving && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  size="sm"
                  className="me-1"
                />
              )}
              Save
            </button>

            <button
              className="bg-white rounded-2 px-3 ms-2"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              close
            </button>
          </Card.Footer>
        </Card>
        <ConfirmModal
          show={deleteConfirm}
          leftTitle="No"
          rightTitle="Yes"
          isLoading={isDeleting}
          onDiscard={() => {
            deleteUser(id, {
              onSuccess: () => {
                getUsers();
                setDeleteConfirm(false);
                setIsEdit(false);
              },
            });
          }}
          onOk={() => {
            setDeleteConfirm(false);
          }}
          content={deleteMsg}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex mb-2 justify-content-between">
        <div className="d-flex">
          <h6 className="text-muted mb-0">Total Users:</h6>
          <h6 className="ms-1 mb-0">{users?.length}</h6>
          {/* <h6 className="text-muted ms-4 mb-0">Total verified Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type === 'contributor').length}
          </h6>
          <h6 className="text-muted ms-4 mb-0">Total regular Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type !== 'contributor').length}
          </h6> */}
        </div>
        <div>
          <button
            className="bg-white rounded-2 px-3"
            onClick={() => {
              reset();
              setIsEdit(true);
            }}
          >
            add user
          </button>
          <button
            className="bg-white rounded-2 px-3 ms-2"
            onClick={() => {
              setAddUsers(true);
            }}
          >
            load bulk users.
          </button>
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th></th> */}
            <th scope="col">username</th>
            <th scope="col">email</th>
            <th scope="col">confirm email</th>
            <th scope="col">location</th>
            <th scope="col">role</th>
            <th scope="col">created time</th>
            <th scope="col">updated time</th>
            <td></td>
            {/* <th>role</th> */}
            {/* <th>valid</th> */}
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((it, idx) => (
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td>
                  {it.member_FirstName} {it.member_LastName}
                </td>
                <td>{it.member_Email}</td>
                <td>{it.member_ConfirmEmail}</td>
                <td>
                  {it.member_City}
                  {it.member_City && ', '}
                  {it.member_Province}
                  {it.member_Province && ', '} {it.member_Country}
                  {it.member_SchoolName}
                </td>
                <td>{levelName[it._Level]}</td>
                <td>{renderTime(it.member_Created)}</td>
                <td>{renderTime(it.member_Updated)}</td>
                <td>
                  <button
                    type="button"
                    className="bg-white rounded-2 px-3 ms-2"
                    onClick={() => onEditItem(it)}
                  >
                    edit
                  </button>
                </td>
                {/* <td>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={renderType(it.member_Type)}
                    checked={isContributor(it.member_Type)}
                    onClick={() => {
                      if (isContributor(it.member_Type)) {
                        setShowMessage(msgRole2);
                      } else {
                        setShowMessage(msgRole1);
                      }
                      checkType.current = 1;
                      setShowConfirm(true);
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
                {/* <td>
                  <Form.Check
                    type='switch'
                    id='custom-switch'
                    label={renderValid(it.member_Verified)}
                    checked={it.member_Verified}
                    onClick={() => {
                      setshowMessage(
                        it.member_Verified ? msgUser1 : msgUser2
                      );
                      setshowConfirm(true);
                      checkType.current = 2;
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
      {/* <ConfirmModal
        show={showConfirm}
        content={showMessage}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setShowConfirm(false);
        }}
        onDiscard={() => {
          afterDiscard();
        }}
      /> */}
      {addUsers && (
        <AddUserModal
          show={addUsers}
          onOk={() => {
            setAddUsers(false);
          }}
          onConfirm={() => {
            setAddUsers(false);
            getUsers();
          }}
        />
      )}
    </div>
  );
};
